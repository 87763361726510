import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Collaboration } from '@pncl/common-models';
import { Observable, firstValueFrom } from 'rxjs';
import { map, retry, tap } from 'rxjs/operators';

import { filterNil } from '@ngneat/elf';
import OpenAI from 'openai';
import { environment } from '../../environments/environment';
import {
  CallContext,
  CallProvider,
  DEFAULT_PROVIDER,
  RTCRoomCreationOpts,
} from '../common/interfaces/rtc-interface';
import { URL_CONSTANTS } from '../common/utils/url';
import { CommonDialogComponent } from '../dialogs/common-dialog/common-dialog.component';
import { ErrorInterceptorSkipHeader } from '../error.interceptor';
import { APIAnalyticsInsightResponse, IAnalyticsInsight } from '../models/analytics';
import { EventWithTimingInfo } from '../sessions/session/attendance/attendance-ui-models';
import {
  MakeAllOptionalExcept,
  Permissions,
  PublicSpaceDetails,
  Room,
  Session,
  SessionUser,
  SpaceSettings,
  Visibility,
} from '../models/session';
import { RecordingAccess, SpaceRecording } from '../models/space-recording';
import { PanelView } from '../sessions/panel/panel.component';
import { LLMAPIResponse } from '../sessions/session/items-canvas/items-canvas.component';
import { SpaceRepository } from '../state/space.repository';
import { RequestAccessService } from '../sessions/session/request-access/request-access.service';
import { WaitingRoomRepoService } from '../sessions/session/request-access/waiting-room/waiting-room-repo.service';
import { URLService } from './dynamic-url.service';
import { FLAGS, FlagsService } from './flags.service';
import { HbSessionResponse, HyperbeamService, WebViewerConfig } from './hyperbeam.service';
import { NetworkCacheService } from './network-cache.service';
import {
  FrameBackground,
  SessionChange,
  SessionSharedDataService,
} from './session-shared-data.service';
import { TelemetryService } from './telemetry.service';
import { UserService } from './user.service';
import { IFilter, changeFiltersToAPIFormat } from './spaces-filter.service';
import Transcription = Collaboration.Transcription;

export interface CreateSpaceOptions {
  spaceToCloneId?: string;
  siteId?: string;
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

const httpOptionsSkipErrorInterceptor = {
  headers: httpOptions.headers.set(ErrorInterceptorSkipHeader, ''),
};

@Injectable({
  providedIn: 'root',
})
export class SpacesService {
  showDialog = true;
  batchingInterval? = 10000; // default value
  spacesAnalyticsEnabled?: boolean;
  spaceAnalyticsInterval?: NodeJS.Timer | number;

  constructor(
    private http: HttpClient,
    private urlService: URLService,
    public dialog: MatDialog,
    public translate: TranslateService,
    private sharedDataService: SessionSharedDataService,
    private router: Router,
    private telemetry: TelemetryService,
    private flagsService: FlagsService,
    private spaceRepo: SpaceRepository,
    private hyperbeamService: HyperbeamService,
    private userService: UserService,
    private networkCacheService: NetworkCacheService,
    private requestAccessService: RequestAccessService,
    private waitingRoomRepoService: WaitingRoomRepoService,
  ) {}

  createSpace(title?: string, options?: CreateSpaceOptions): Observable<any> {
    // Log that a new space was created.
    this.telemetry.event('user-first-space-visit-organic');
    const spaceUrl = `${this.urlService.getDynamicUrl()}/spaces/create`;
    return this.http
      .post(
        spaceUrl,
        JSON.stringify({
          title,
          spaceToCloneId: options?.spaceToCloneId,
          site: options?.siteId,
          provider: this.flagsService.isFlagEnabled(FLAGS.SPACE_VIDEO_CALL_PROVIDER)
            ? (this.flagsService.featureFlagsVariables?.cpaas_provider?.provider as string) ||
              DEFAULT_PROVIDER
            : DEFAULT_PROVIDER,
          breakoutRoomsEnabled: this.flagsService.isFlagEnabled(FLAGS.BREAKOUT_ROOMS),
        }),
        httpOptions,
      )
      .pipe(
        tap(async (response) => {
          // if update is successful, clear network cache for space list to get updated list
          // on the next call for the spaces endpoint
          if (response) {
            await this.networkCacheService.clearSpacesListCache();
          }
        }),
      )
      .pipe(retry(3));
  }

  endSession(sessionId: string): Observable<any> {
    const endSessionUrl = `${this.urlService.getDynamicUrl()}/spaces/${sessionId}/endSession`;
    return this.http.post(endSessionUrl, JSON.stringify({}), httpOptions);
  }

  compactSessions(sessionIds: string[]) {
    const compactSessionsUrl = `${this.urlService.getDynamicUrl()}/spaces/compact`;
    return this.http.post(
      compactSessionsUrl,
      JSON.stringify({ sessionIds: sessionIds }),
      httpOptions,
    );
  }

  reinitializeSessions(sessionIds: string[]) {
    const reinitializeSessionsUrl = `${this.urlService.getDynamicUrl()}/spaces/reinitialise`;
    return this.http.post(
      reinitializeSessionsUrl,
      JSON.stringify({ sessionIds: sessionIds }),
      httpOptions,
    );
  }

  cloneSessions(data: FormData) {
    const compactSessionsUrl = `${this.urlService.getDynamicUrl()}/spaces/clone`;
    return this.http.post(compactSessionsUrl, data);
  }

  changeProvider(
    data: { spaceIds: string[]; provider: string } | { institutionIds: string[]; provider: string },
  ) {
    const changeProviderUrl = `${this.urlService.getDynamicUrl()}/spaces/changeProvider`;
    return this.http.post<{ numberOfUpdatedSpaces: number }>(changeProviderUrl, data);
  }

  getSession(
    id: string,
  ): Observable<{ session: Session; isAcceptingEmailInvite: boolean | undefined }> {
    const getSessionUrl = `${this.urlService.getDynamicUrl()}/spaces/${id}/`;
    return this.http
      .get<{ session: Session; isAcceptingEmailInvite: boolean | undefined }>(getSessionUrl)
      .pipe(
        tap(async (res) => {
          const user = await firstValueFrom(
            this.userService.user.pipe(
              filterNil(),
              map((u) => u.user),
            ),
          );

          this.spaceRepo.addSpace(
            res.session,
            user,
            this.flagsService.isFlagEnabled(FLAGS.BREAKOUT_ROOMS),
          );
        }),
      );
  }

  getPublicSpaceDetails(id: string): Observable<PublicSpaceDetails> {
    const publicSessionDetailsUrl = `${this.urlService.getDynamicUrl()}/spaces/public-details/${id}/`;
    return this.http.get<PublicSpaceDetails>(publicSessionDetailsUrl);
  }

  getSpaceVideoCallProvider(id: string): Observable<any> {
    const getSpaceVideoCallProviderUrl = `${this.urlService.getDynamicUrl()}/spaces/callProvider/${id}/`;
    return this.http.get(getSpaceVideoCallProviderUrl);
  }

  getSessions(): Observable<any> {
    const getSessionsUrl = `${this.urlService.getDynamicUrl()}/spaces/`;
    return this.http.get(getSessionsUrl);
  }

  // adding fromDate for backward compatibility, remove during sessions cleanup
  /**
   * get spaces ( sessions ) from API
   * @param fromDate
   * @param filters
   * @param pageNumber the current page number
   * @param limit the limit of the results
   * @param skip number of spaces to skip instead of using pageNumber
   * @param withDescSorting if we should get spaces with descending sort
   */
  getSpacesList(
    fromDate?: string,
    filters?: IFilter,
    pageNumber?: number,
    limit?: number,
    skip?: number,
    withDescSorting?: boolean,
  ): Observable<any> {
    const encodedFilters = filters
      ? btoa(encodeURIComponent(JSON.stringify(changeFiltersToAPIFormat(filters))))
      : '';
    let query = `filters=${encodedFilters}`;
    if (pageNumber) {
      query += `&pageNumber=${pageNumber}`;
    }

    if (limit) {
      query += `&pageSize=${limit}`;
    }

    if (skip) {
      query += `&skip=${skip}`;
    }

    if (withDescSorting) {
      query += '&sort=desc';
    }

    return this.http.get(`${this.urlService.getDynamicUrl()}/spaces?${query}`);
  }

  get isMyFirstSpace$(): Observable<boolean> {
    return this.http
      .get<{ sessions: any[]; totalRecords: number }>(
        `${this.urlService.getDynamicUrl()}/spaces?pageNumber=1&pageSize=1`,
      )
      .pipe(
        map((data) => data.totalRecords === 1 && data.sessions[0]['title'] === 'My First Space'),
      );
  }

  getSessionAuth(id: string): Observable<Partial<Session>> {
    const getSessionAuthUrl = `${this.urlService.getDynamicUrl()}/spaces/auth/${id}`;
    return this.http.get(getSessionAuthUrl);
  }

  getSessionLobby(id: string): Observable<any> {
    const getSessionLobbyUrl = `${this.urlService.getDynamicUrl()}/spaces/lobby/${id}/`;
    return this.http.get(getSessionLobbyUrl);
  }

  getSessionsAuth(ids: string[]): Observable<any> {
    let getSessionsUrl = `${this.urlService.getDynamicUrl()}/spaces/auth/list?ids=`;
    getSessionsUrl = getSessionsUrl + JSON.stringify(ids);
    return this.http.get(getSessionsUrl);
  }

  updateSession(sessionId: string, session: Partial<Session>): Observable<any> {
    const saveURL = `${this.urlService.getDynamicUrl()}/spaces/${sessionId}`;
    return this.http.patch(saveURL, JSON.stringify({ session }), httpOptions).pipe(retry(3));
  }

  updateSessionUsers(
    sessionId: string,
    addUsers: SessionUser[] = [],
    removeUsers: SessionUser[] = [],
    inviteText = '',
    sendEmail = true,
    notifyNewlyAddedOnly = true,
  ) {
    if (removeUsers.length > 0) {
      this.waitingRoomRepoService.emitUsersRemovedFromSpace(removeUsers.map((user) => user._id));
    }
    const saveURL = `${this.urlService.getDynamicUrl()}/spaces/${sessionId}/updateUsers`;
    const updateObject: any = {
      addUsers,
      removeUsers,
      inviteText,
      sendEmail,
      notifyNewlyAddedOnly,
    };
    return this.http
      .patch(saveURL, JSON.stringify(updateObject), {
        ...httpOptions,
        observe: 'response',
      })
      .pipe(
        tap(async (response) => {
          // if update is successful, clear network cache for space list to get updated users
          // on the next call for the spaces endpoint
          if (response.ok) {
            await this.networkCacheService.clearSpacesListCache();
          }
        }),
      );
  }

  sendRecordingURL(sessionId: string, userId: string, recordingId: string): Observable<any> {
    const URL = `${this.urlService.getDynamicUrl()}/spaces/sendRecordingURL/${sessionId}`;
    return this.http.post<any>(URL, JSON.stringify({ recordingId }), httpOptions).pipe(retry(3));
  }

  updateVisibility(sessionId: string, visibility: Visibility) {
    if (
      visibility === Visibility.PUBLIC &&
      this.requestAccessService.shouldShowAccessRequestsValue()
    ) {
      this.requestAccessService.approveAll();
    }
    const saveURL = `${this.urlService.getDynamicUrl()}/spaces/${sessionId}`;
    const body = { session: { visibility: visibility } };
    return this.http
      .patch(saveURL, JSON.stringify(body), {
        ...httpOptions,
        observe: 'response',
      })
      .pipe(
        tap(async (response) => {
          // if update is successful, clear network cache for space list to get updated visibility
          // on the next call for the spaces endpoint
          if (response.ok) {
            await this.networkCacheService.clearSpacesListCache();
          }
        }),
      );
  }

  updateSettings(
    sessionId: string,
    settings: SpaceSettings,
    skipErrorInterceptor: boolean = false,
  ) {
    if (!settings.enableWaitingRoom) {
      this.waitingRoomRepoService.emitAcceptAllInWR();
    }

    const url = `${this.urlService.getDynamicUrl()}/spaces/${sessionId}/settings`;
    const body = { settings };

    return this.http
      .patch(url, JSON.stringify(body), {
        ...(skipErrorInterceptor ? httpOptionsSkipErrorInterceptor : httpOptions),
        observe: 'response',
      })
      .pipe(
        tap(async (response) => {
          // if update is successful, clear network cache for space list to get updated list
          // on the next call for the spaces endpoint
          if (response.ok) {
            await this.networkCacheService.clearSpacesListCache();
          }
        }),
      );
  }

  updateLockState(sessionId: string, isLocked: boolean) {
    const saveURL = `${this.urlService.getDynamicUrl()}/spaces/${sessionId}`;
    const body = { session: { isLocked: isLocked } };
    return this.http.patch(saveURL, JSON.stringify(body), {
      ...httpOptions,
      observe: 'response',
    });
  }

  updateSessionPermissions(
    sessionId: string,
    permissions: Permissions,
    skipErrorInterceptor: boolean = false,
  ) {
    const saveURL = `${this.urlService.getDynamicUrl()}/spaces/${sessionId}`;
    const body = { session: { sessionPermissions: permissions } };
    return this.http.patch(saveURL, JSON.stringify(body), {
      ...(skipErrorInterceptor ? httpOptionsSkipErrorInterceptor : httpOptions),
      observe: 'response',
    });
  }

  updateBackgroundDefault(sessionId: string, background: FrameBackground) {
    const saveURL = `${this.urlService.getDynamicUrl()}/spaces/${sessionId}`;
    const body = { session: { sessionDefaultFramesBackground: background } };
    return this.http.patch(saveURL, JSON.stringify(body), {
      ...httpOptions,
      observe: 'response',
    });
  }
  updateUsersPermissions(sessionId: string, users: SessionUser[]) {
    const saveURL = `${this.urlService.getDynamicUrl()}/spaces/${sessionId}`;
    const body = { session: { users: users } };
    return this.http.patch(saveURL, JSON.stringify(body), {
      ...httpOptions,
      observe: 'response',
    });
  }

  /**
   * Sends https request to update session's title if the title
   * is empty a new random title is set.
   * @param sessionId the id of the session to be modified.
   * @param title the new title of the session.
   */
  updateTitle(sessionId: string, title: string) {
    const saveURL = `${this.urlService.getDynamicUrl()}/spaces/${sessionId}`;
    const body = { session: { title: title } };
    return this.http
      .patch(saveURL, JSON.stringify(body), {
        ...httpOptions,
        observe: 'response',
      })
      .pipe(
        tap(async (response) => {
          // if update is successful, clear network cache for space list to get updated title
          // on the next call for the spaces endpoint
          if (response.ok) {
            await this.networkCacheService.clearSpacesListCache();
          }
        }),
      );
  }

  updateSpaceVideoCallProvider(sessionId: string, provider: string) {
    const saveURL = `${this.urlService.getDynamicUrl()}/spaces/${sessionId}`;
    const body = { session: { provider: provider } };
    return this.http.patch(saveURL, JSON.stringify(body), {
      ...httpOptions,
      observe: 'response',
    });
  }

  deleteSession(id: string): Observable<any> {
    const deleteSessionUrl = `${this.urlService.getDynamicUrl()}/spaces/${id}`;
    return this.http.delete(deleteSessionUrl).pipe(
      tap(async (response) => {
        // if update is successful, clear network cache for space list to get updated list
        // on the next call for the spaces endpoint
        if (response) {
          await this.networkCacheService.clearSpacesListCache();
        }
      }),
    );
  }

  async navigateToAnotherSession(
    id: string,
    bypassShowDialog = false,
    newShowDialog?: boolean,
  ): Promise<boolean | undefined> {
    const oldSessionId = this.spaceRepo.activeSpace?._id;
    const oldBreakoutRoomId = this.spaceRepo.activeSpaceCurrentRoomUid;
    if (bypassShowDialog) {
      this.showDialog = !!newShowDialog;
    }
    if (this.showDialog) {
      const promise = this.dialog
        .open(CommonDialogComponent, {
          width: '500px',
          data: {
            title: this.translate.instant('Leave Space'),
            content:
              // eslint-disable-next-line max-len
              this.translate.instant(
                'By joining a different space, you will leave the current space’s video call. Are you sure you want to do this?',
              ),
            okButtonText: this.translate.instant('Yes'),
            checkboxText: this.translate.instant("Don't show me this again."),
          },
          panelClass: 'file-dialog',
        })
        .afterClosed()
        .pipe(
          map((result: { canceled: boolean; checked: boolean }) => {
            if (!result.canceled) {
              if (
                this.sharedDataService.leftPanelView?.value?.panelView !==
                PanelView.parallelSessions
              ) {
                this.sharedDataService.changeLeftPanelView.next(undefined);
                this.sharedDataService.changeRightPanelView.next(undefined);
              }
              if (id) {
                this.sharedDataService.dataLoading.next(true);
              }

              if (result.checked) {
                this.showDialog = false;
              }

              const sessionChange: SessionChange = {
                oldSessionId: oldSessionId ?? undefined,
                newSessionId: id,
                oldBreakoutRoomId: oldBreakoutRoomId,
              };
              this.spaceRepo.resetActiveSpace();
              this.sharedDataService.sessionChanged.next(sessionChange);

              if (!id) {
                return this.router.navigate([`/${URL_CONSTANTS.SPACES}`], {});
              } else {
                return this.router.navigate([`${URL_CONSTANTS.SPACES}/${id}`], {
                  queryParamsHandling: 'preserve',
                });
              }
            }
          }),
        );
      return promise.toPromise();
    } else {
      if (id) {
        this.sharedDataService.dataLoading.next(true);
      }
      if (this.sharedDataService.leftPanelView?.value?.panelView !== PanelView.parallelSessions) {
        this.sharedDataService.changeLeftPanelView.next(undefined);
        this.sharedDataService.changeRightPanelView.next(undefined);
      }
      const sessionChange: SessionChange = {
        oldSessionId: oldSessionId ?? undefined,
        newSessionId: id,
        oldBreakoutRoomId: oldBreakoutRoomId,
      };
      this.spaceRepo.resetActiveSpace();
      this.sharedDataService.sessionChanged.next(sessionChange);

      if (!id) {
        return this.router.navigate([`/${URL_CONSTANTS.SPACES}`], {});
      } else {
        return this.router.navigate([`${URL_CONSTANTS.SPACES}/${id}`], {
          queryParamsHandling: 'preserve',
        });
      }
    }
  }

  sendAnalyticsEvents(
    currentSessionId: string,
    analyticsEvents: Array<any>,
    realTimeInsights: Array<any>,
  ) {
    const spaceId = this.spaceRepo.activeSpace?._id;
    if (!spaceId) {
      return;
    }
    const url = `${this.urlService.getDynamicUrl()}/rtevents/event/${spaceId}`;
    const body = {
      currentSessionId,
      analyticsEvents,
      realTimeInsights,
    };
    return this.http.post(url, JSON.stringify(body), httpOptions).pipe(retry(3));
  }

  getTranscriptions(
    spaceId: string,
    roomId?: string,
    timestamp?: string,
    size?: number,
  ): Observable<Transcription.TranscriptionsResponse> {
    const url = `${this.urlService.getDynamicUrl()}/spaces/transcriptionItems/${spaceId}`;
    const query = { roomId: roomId || '', timestamp: timestamp || '', size: size || '' };
    return this.http.get<Transcription.TranscriptionsResponse>(url, { params: query });
  }

  saveTranscript(
    spaceId: string,
    transcript: string,
    lang: string,
    metadata?: any,
    sessionId?: string,
    breakoutRoomId?: string,
  ) {
    const saveTranscriptBody = {
      transcript: transcript,
      language: lang,
      sessionId: sessionId,
      roomId: breakoutRoomId,
      metadata: metadata,
    };

    const saveURL = `${this.urlService.getDynamicUrl()}/spaces/${spaceId}/transcriptItem`;

    return this.http.patch(saveURL, JSON.stringify(saveTranscriptBody), httpOptions).pipe(retry(3));
  }

  editTranscript(spaceId: string, transcriptId: string, transcript: string) {
    const saveTranscriptBody = {
      transcript: transcript,
    };

    const saveURL = `${this.urlService.getDynamicUrl()}/spaces/${spaceId}/transcriptItem/${transcriptId}`;

    return this.http.patch(saveURL, JSON.stringify(saveTranscriptBody), httpOptions).pipe(retry(3));
  }

  deleteTranscript(spaceId: string, transcriptId: string) {
    const saveURL = `${this.urlService.getDynamicUrl()}/spaces/${spaceId}/transcriptItem/${transcriptId}`;

    return this.http.delete(saveURL, httpOptions).pipe(retry(3));
  }

  setSpaceTranscriptStatus(spaceId: string, status: boolean) {
    const url = `${this.urlService.getDynamicUrl()}/spaces/transcriptStatus/${spaceId}`;
    return this.http.patch(url, { status: status });
  }

  setSpaceTranscriptServiceType(spaceId: string, serviceType: string) {
    const url = `${this.urlService.getDynamicUrl()}/spaces/transcriptServiceType/${spaceId}`;
    return this.http.patch(url, { serviceType: serviceType });
  }

  validateIframeUrl(url: string) {
    const reqUrl = `${this.urlService.getDynamicUrl()}/spaces/url/validate`;
    return this.http.post(reqUrl, { url });
  }

  async createWebViewerSession(
    hbSessionId: string | undefined,
    webViewerConfig: WebViewerConfig,
  ): Promise<HbSessionResponse | undefined> {
    try {
      return await this.hyperbeamService.createHbSession(hbSessionId, webViewerConfig);
    } catch (error) {
      console.log(error);
    }
  }

  getSessionBySpace(
    spaceId: string,
    pageSize: number,
    pageNumber: number,
  ): Observable<APIAnalyticsInsightResponse> {
    const filters = btoa(
      JSON.stringify({
        spaceIds: [spaceId],
      }),
    );
    return this.http.get<APIAnalyticsInsightResponse>(
      `${this.urlService.getDynamicUrl()}/analytics/sessions?filters=${filters}&pageSize=${pageSize}&pageNumber=${pageNumber}&fromFE=true&callsOnly=true`,
    );
  }

  getAnalyticsSessionById(sessionId: string): Observable<IAnalyticsInsight> {
    return this.http.get<IAnalyticsInsight>(
      `${this.urlService.getDynamicUrl()}/analytics/sessions/${sessionId}?fromFE=true`,
    );
  }

  backupSpacesToCloud(spaceIds: string[]) {
    const backupSpacesUrl = `${this.urlService.getDynamicUrl()}/spaces/backup`;
    return this.http.post(backupSpacesUrl, JSON.stringify({ spaceIds }), httpOptions);
  }

  renameSessionTitle(sessionId: string, newSessionTitle: string): Observable<any> {
    const body = JSON.stringify({ newSessionTitle });
    return this.http.patch(
      `${this.urlService.getDynamicUrl()}/analytics/sessions/${sessionId}`,
      body,
      httpOptions,
    );
  }

  endOngoingSession(spaceId: string): Observable<any> {
    return this.http.post(
      `${this.urlService.getDynamicUrl()}/spaces/${spaceId}/endOngoingSession?fromFE=true`,
      {},
      httpOptions,
    );
  }

  changeCloudRecordingState(
    RTCProvider: CallProvider,
    spaceId: string,
    RTCRoomId: string,
    state: boolean,
    recordingId?: string,
  ): Observable<any> {
    return this.http.post(
      `${this.urlService.getDynamicUrl()}/spaces/${spaceId}/changeRoomCloudRecordingState`,
      JSON.stringify({
        provider: RTCProvider,
        RTCRoomId,
        roomCloudRecordingState: state,
        recordingId,
      }),
      httpOptionsSkipErrorInterceptor,
    );
  }

  getEventsBySession(sessionId: string): Observable<any> {
    return this.http.get(`${this.urlService.getDynamicUrl()}/rtevents/event/${sessionId}`);
  }

  getAttendanceEventsInSpace(spaceId: string, time: number): Observable<EventWithTimingInfo[]> {
    return this.http.get<EventWithTimingInfo[]>(
      `${this.urlService.getDynamicUrl()}/spaces/${spaceId}/event/?time=${time}`,
    );
  }

  getVideoCallAuth(
    spaceId: string,
    breakoutRoomId: string | undefined,
    context: CallContext,
    provider: CallProvider,
    opts?: RTCRoomCreationOpts,
  ) {
    let joinRoomUrl = this.urlService.getDynamicUrl();
    switch (context) {
      case CallContext.SESSION:
        joinRoomUrl += `/spaces/rtc-auth/${spaceId}`;
        break;
    }
    let query = `?provider=${provider}`;

    if (breakoutRoomId) {
      query += `&roomId=${breakoutRoomId}`;
    }

    if (opts) {
      query += `&skipRoomCreation=${opts.skipRoomCreation}&skipGenerateToken=${opts.skipGenerateToken}`;
    }

    return this.http.get(joinRoomUrl + query, {
      ...httpOptions,
      observe: 'response',
    });
  }

  getSingleRecording(recordingId: string): Observable<SpaceRecording> {
    const url = `${this.urlService.getDynamicUrl()}/spaces/recordings/${recordingId}/view`;
    const headerUpdates = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }).set(
        ErrorInterceptorSkipHeader,
        '',
      ),
    };
    return this.http.get<SpaceRecording>(url, headerUpdates);
  }

  getRecordings(
    spaceId: string,
    startingAfter: string,
    endingBefore: string,
  ): Observable<{ recordings: SpaceRecording[] }> {
    const url = `${this.urlService.getDynamicUrl()}/spaces/recordings/${spaceId}?startingAfter=${startingAfter}&endingBefore=${endingBefore}`;
    return this.http.get<{ recordings: SpaceRecording[] }>(url, httpOptions);
  }

  deleteRecording(spaceId: string, recordingId: string): Observable<{ msg: string }> {
    const url = `${this.urlService.getDynamicUrl()}/spaces/recordings/${spaceId}/${recordingId}`;
    return this.http.delete<{ msg: string }>(url, httpOptions);
  }

  updateRecordingAccess(recordingId: string, access: RecordingAccess): Observable<any> {
    return this.http.patch(
      `${this.urlService.getDynamicUrl()}/spaces/recordings/${recordingId}`,
      { access },
      httpOptions,
    );
  }

  getSignedUrl(url: string): Observable<string[]> {
    return this.http
      .post<string[]>(
        environment.resourceSignerServer,
        JSON.stringify({
          url,
        }),
        httpOptions,
      )
      .pipe(retry(3));
  }

  createBreakoutRoom(spaceId: string, rooms: Room[]) {
    const url = `${this.urlService.getDynamicUrl()}/spaces/rooms/${spaceId}`;
    const roomsWithoutPermissions = rooms.map(({ permissions, ...rest }) => rest);
    return this.http
      .post(
        url,
        JSON.stringify({
          spaceId,
          rooms: roomsWithoutPermissions,
        }),
        httpOptions,
      )
      .pipe(retry(3));
  }

  modifyBreakoutRoom(spaceId: string, room: MakeAllOptionalExcept<Room, 'uid'>) {
    const url = `${this.urlService.getDynamicUrl()}/spaces/rooms/${spaceId}`;
    return this.http.patch(url, JSON.stringify(room), httpOptions).pipe(retry(3));
  }

  deleteBreakoutRoom(spaceId: string, roomUid: string) {
    const url = `${this.urlService.getDynamicUrl()}/spaces/rooms/${spaceId}/${roomUid}`;
    return this.http.delete(url).pipe(retry(3));
  }

  queryPencilAssistant(query: string, config: any): Observable<LLMAPIResponse> {
    const url = environment.openAiServer;
    return this.http
      .post<LLMAPIResponse>(
        url,
        JSON.stringify({
          query,
          config,
        }),
        httpOptions,
      )
      .pipe(retry(3));
  }

  querySparky(
    chatHistory: Array<OpenAI.Chat.ChatCompletionMessageParam>,
    userContext: { _id: string; name: string },
  ): Observable<{ response: OpenAI.Chat.ChatCompletion.Choice }> {
    const url = `${environment.openAiServer}/sparky`;
    return this.http
      .post<{ response: OpenAI.Chat.ChatCompletion.Choice }>(
        url,
        JSON.stringify({ chatHistory, userContext }),
        httpOptions,
      )
      .pipe(retry(3));
  }
}
