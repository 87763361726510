import { Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Site, User } from '../models/user';
import { DateRangePickerSelectionNoLabelPipe } from '../pipes/data-range-picker-selection-no-label.pipe';

export interface IFilter {
  query?: string;
  createdRange?: DateRange<Date | null>;
  dateRange?: DateRange<Date | null>;
  owner?: User;
  hosts?: User[];
  participants?: User[];
  sites?: Site[];
  institution?: string;
}

export interface IFilterPill {
  id: keyof IFilter;
  tag: string;
  value: string;
}

export interface IFilterAPI {
  query?: string;
  createdRange?: DateRange<Date | null>;
  dateRange?: DateRange<Date | null>;
  owner?: string;
  hosts?: string[];
  participants?: string[];
  sites?: string[];
}

@Injectable()
export class SpacesFilterService {
  constructor(private translateService: TranslateService) {}
  private _appliedFilters = new BehaviorSubject<IFilter>({});
  private _filterPills = new BehaviorSubject<IFilterPill[]>([]);
  public appliedFilters$ = this._appliedFilters.asObservable();
  public filterPills$ = this._filterPills.asObservable();

  get appliedFilters() {
    return this._appliedFilters.value;
  }

  get filterPills() {
    return this._filterPills.value;
  }

  clearFilters(): void {
    this._appliedFilters.next(
      this._appliedFilters.value.query
        ? {
            query: this._appliedFilters.value.query,
          }
        : {},
    );
    this._filterPills.next([]);
  }

  updateFilter(filter: IFilter): void {
    const filtered = Object.keys(filter)
      .filter((key) => filter[key as keyof IFilter] !== undefined)
      .reduce((obj, key) => {
        obj[key as keyof IFilter] = filter[key as keyof IFilter] as any;
        return obj;
      }, {} as IFilter);

    this._appliedFilters.next({ ...this.appliedFilters, ...filtered });
    this._filterPills.next(this.generateFilterPills(this.appliedFilters));
  }

  removeFilter(filter: keyof IFilter): void {
    const { [filter]: removed, ...remainingFilters } = this.appliedFilters;
    this._appliedFilters.next(remainingFilters);
    this._filterPills.next(this.generateFilterPills(this.appliedFilters));
  }

  private addFilterPill(id: keyof IFilter, tag: string, value: any, filterPills: IFilterPill[]) {
    filterPills.push({
      id,
      tag: this.translateService.instant(tag),
      value,
    });
  }

  private generateHostsOrParticipantsPillValue(entities: any[]) {
    const otherText: string = this.translateService.instant(
      entities.length - 1 === 1 ? 'other' : 'others',
    );
    const containsAnyOfText: string = this.translateService.instant('Contains any of');
    const andText: string = this.translateService.instant('and');

    // Output examples:
    // entities.length === 1, "John Doe" or "johndoe@gmail.com" if name is not available
    // entities.length > 1, "Contains any of John Doe and 2 others"
    return entities.length === 1
      ? entities[0].name ?? entities[0].email
      : `${containsAnyOfText} ${entities[0].name ?? entities[0].email} ${andText} ${
          entities.length - 1
        } ${otherText}`;
  }

  private generateSitesPillValue(sites: Site[]): string {
    const otherText: string = this.translateService.instant(
      sites.length === 2 ? 'other' : 'others',
    );
    const anyOf: string = this.translateService.instant('Any of');
    const andText: string = this.translateService.instant('and');

    // Output examples:
    // sites.length === 1, "Site name"
    // sites.length === 2, "Any of Test Site and 1 other"
    // sites.length > 2, "Any of Test Site and 2 others"
    return sites.length === 1
      ? `${sites[0].name}`
      : `${anyOf} "${sites[0].name}" ${andText} ${sites.length - 1} ${otherText}`;
  }

  generateFilterPills(filters: IFilter): IFilterPill[] {
    const filterPills: IFilterPill[] = [];

    if (filters.owner) {
      this.addFilterPill('owner', 'IS OWNER', 'Yes', filterPills);
    }

    if (filters.createdRange) {
      this.addFilterPill(
        'createdRange',
        'CREATED',
        new DateRangePickerSelectionNoLabelPipe(this.translateService).transform(
          filters.createdRange,
        ),
        filterPills,
      );
    }

    if (filters.dateRange) {
      this.addFilterPill(
        'dateRange',
        'LAST UPDATED',
        new DateRangePickerSelectionNoLabelPipe(this.translateService).transform(filters.dateRange),
        filterPills,
      );
    }

    if (filters.hosts) {
      this.addFilterPill(
        'hosts',
        filters.hosts.length === 1 ? 'HOST' : 'HOSTS',
        this.generateHostsOrParticipantsPillValue(filters.hosts),
        filterPills,
      );
    }

    if (filters.participants) {
      this.addFilterPill(
        'participants',
        filters.participants.length === 1 ? 'PARTICIPANT' : 'PARTICIPANTS',
        this.generateHostsOrParticipantsPillValue(filters.participants),
        filterPills,
      );
    }

    if (filters.sites) {
      this.addFilterPill(
        'sites',
        filters.sites.length === 1 ? 'SITE' : 'SITES',
        this.generateSitesPillValue(filters.sites),
        filterPills,
      );
    }

    return filterPills;
  }
}

export function changeFiltersToAPIFormat(filters: IFilter): IFilterAPI {
  const { owner, hosts, participants, sites, ...otherFilters } = filters;
  const newFilters: IFilterAPI = {};

  if (owner) {
    newFilters.owner = owner._id;
  }
  if (hosts) {
    newFilters.hosts = hosts.map((user: User) => user._id ?? user.email);
  }
  if (participants) {
    newFilters.participants = participants.map((user: User) => user._id ?? user.email);
  }

  if (sites) {
    newFilters.sites = sites.map((site) => site._id);
  }

  return { ...newFilters, ...otherFilters };
}
