import { Injectable, NgZone } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  Observable,
  Subject,
  distinctUntilChanged,
  filter,
  map,
  scan,
  shareReplay,
  switchMap,
} from 'rxjs';
import { isEqual } from 'lodash';
import { filterNil } from '@ngneat/elf';
import * as Sentry from '@sentry/browser';
import {
  ActiveSpeaker,
  CallError,
  CallParticipant,
  DEFAULT_PROVIDER,
  NetworkEvent,
  ParticipantEvent,
  ParticipantEventAction,
  RecordingEvent,
  TrackEvent,
  TranscriptResult,
} from '../common/interfaces/rtc-interface';
import { enterZone } from '../utilities/ZoneUtils';
import { KeyScenariosOnSpaces, TelemetryService } from './telemetry.service';
import { UserService } from './user.service';
import { FLAGS, FlagsService } from './flags.service';
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ProviderStateService {
  // Broadcasts whether or not we have the data necessary to join a call.
  private callReadySubject = new BehaviorSubject<boolean>(false);

  private callConnectedSubject = new BehaviorSubject<boolean>(false);
  public callConnected$ = this.callConnectedSubject.pipe(enterZone(this.ngZone), shareReplay(1));

  // Broadcasts whether or not we're trying to join a call with the client.
  private callConnectingSubject = new BehaviorSubject<boolean>(false);
  // Broadcasts whether or not we're trying to disconnect from call with the client.
  private callDisConnectingSubject = new BehaviorSubject<boolean>(false);

  // Broadcasts participant events with updated participant objects when particiapnts join, are updated, or leave.
  private participantEventsSubject = new BehaviorSubject<ParticipantEvent | null>(null);
  public participantEvents$ = this.participantEventsSubject.pipe(
    enterZone(this.ngZone),
    shareReplay(1),
  );

  // Broadcast the participant object once his metadata is changed
  // This is created to decrease CDs that will be created if we use ParticipantEventAction.UPDATED
  // This optimization only applies to LiveKit as Daily doesn't provide an explicit event for metadata change, so ParticipantEventAction.UPDATED will be always emitted in Daily
  private participantMetadataChangeSubject = new BehaviorSubject<CallParticipant | null>(null);
  public participantMetadataChange$ = this.participantMetadataChangeSubject.pipe(shareReplay(1));

  // Broadcasts track events with updated participant objects when tracks are started or stopped.
  private trackEventsSubject = new BehaviorSubject<TrackEvent | null>(null);
  // Broadcasts network events to track network quality.
  private networkEventsSubject = new BehaviorSubject<NetworkEvent | null>(null);
  // Broadcasts call error
  private callErrorsSubject = new Subject<CallError>();
  public callErrors$ = this.callErrorsSubject.pipe(enterZone(this.ngZone));

  // Broadcasts local participant ID.
  private localParticipantIdSubject = new BehaviorSubject<string>('');
  // Broadcasts whether or not we are reconnecting to a call.
  private callReconnectingSubject = new BehaviorSubject<boolean>(false);

  private sameUserJoinedFromDifferentDevice = new Subject<void>();

  private _currentProvider: string = DEFAULT_PROVIDER;

  private currentActiveSpeakerSubject = new BehaviorSubject<ActiveSpeaker | undefined>(undefined);

  private transcriptMessageSubject = new Subject<TranscriptResult>();

  private recordingEventSubject = new BehaviorSubject<RecordingEvent | undefined>(undefined);

  private _localParticipant?: CallParticipant;

  constructor(
    private ngZone: NgZone,
    private telemetry: TelemetryService,
    private userService: UserService,
    private flagsService: FlagsService,
  ) {}

  // #region Observable getter
  // Getters methods wrapped up with enter zone to make sure the view gets last update

  get recordingEvent$(): Observable<RecordingEvent> {
    return this.recordingEventSubject.pipe(filterNil(), enterZone(this.ngZone));
  }

  get callReady$(): Observable<boolean> {
    return this.callReadySubject.pipe(enterZone(this.ngZone));
  }

  get callReadyValue(): boolean {
    return this.callReadySubject.value;
  }

  get callConnecting$(): Observable<boolean> {
    return this.callConnectingSubject.pipe(enterZone(this.ngZone));
  }

  get callConnecting(): boolean {
    return this.callConnectingSubject.getValue();
  }

  get callDisconnecting$(): Observable<boolean> {
    return this.callDisConnectingSubject.pipe(enterZone(this.ngZone));
  }

  get callDisconnecting(): boolean {
    return this.callDisConnectingSubject.getValue();
  }

  get callConnectedValue(): boolean {
    return this.callConnectedSubject.value;
  }

  get trackEvents$(): Observable<TrackEvent | null> {
    return this.trackEventsSubject.pipe(enterZone(this.ngZone));
  }

  get networkEvents$(): Observable<NetworkEvent | null> {
    return this.networkEventsSubject.pipe(enterZone(this.ngZone));
  }

  get localParticipantId$(): Observable<string> {
    return this.localParticipantIdSubject.pipe(enterZone(this.ngZone));
  }

  get localParticipantIdValue(): string {
    return this.localParticipantIdSubject.getValue();
  }

  get callReconnecting$(): Observable<boolean> {
    return this.callReconnectingSubject.pipe(enterZone(this.ngZone));
  }

  get callReconnectingValue(): boolean {
    return this.callReconnectingSubject.getValue();
  }

  get currentActiveSpeaker$() {
    return this.currentActiveSpeakerSubject.asObservable();
  }

  get transcriptMessage$() {
    return this.transcriptMessageSubject.asObservable();
  }

  get sameUserJoinedFromDifferentDevice$() {
    return this.sameUserJoinedFromDifferentDevice.asObservable();
  }

  get recordingEvent(): RecordingEvent | undefined {
    return this.recordingEventSubject.value;
  }

  // #endregion Observable getter

  // #region Observable setter
  // Setters methods to guarantee a source of truth for a change

  set currentProvider(newProvider: string) {
    if (this._currentProvider !== newProvider) {
      this._currentProvider = newProvider;
      this.resetBehaviourSubs();
    }
  }

  set callReady(val: boolean) {
    if (this.callReadySubject.value !== val) {
      this.callReadySubject.next(val);
    }
  }

  set callConnected(val: boolean) {
    if (this.callConnectedSubject.value !== val) {
      this.telemetry.setSessionVars({
        user_in_call: val,
      });
      this.callConnectedSubject.next(val);
    }
  }

  set callConnecting(val: boolean) {
    if (this.callConnectingSubject.value !== val) {
      this.callConnectingSubject.next(val);
    }
  }

  set callDisconnecting(val: boolean) {
    if (this.callDisconnecting !== val) {
      this.callDisConnectingSubject.next(val);
    }
  }

  set participantEvents(val: ParticipantEvent | null) {
    if (val) {
      this.logMeetingEventsToTelemetry(val);

      if (!!val.participant?.local && val.action === ParticipantEventAction.JOINED) {
        this.logFirstJoinCallAttemptLocalParticipant();
        this.logPerfScenarioJoinCallProcess(val.participant.userId);
        this._localParticipant = val.participant;
      }
      if (
        !this.flagsService.isFlagEnabled(FLAGS.ENABLE_MULTIPLE_JOIN_CALL_FROM_THE_SAME_ACCOUNT) &&
        this.isSameUserJoinedFromAnotherDevice(val)
      ) {
        this.handleMultiJoinCallFromTheSameUser(val);
        // return to not show the user that will be ejected from the call
        return;
      }
    }
    if (!isEqual(this.participantEventsSubject.value, val)) {
      this.participantEventsSubject.next(val);
    }
  }

  set trackEvents(val: TrackEvent | null) {
    if (val) {
      this.logMeetingEventsToTelemetry(val);
    }
    if (!isEqual(this.trackEventsSubject.value, val)) {
      this.trackEventsSubject.next(val);
    }
  }

  set networkEvents(val: NetworkEvent | null) {
    if (!isEqual(this.networkEventsSubject.value, val)) {
      this.networkEventsSubject.next(val);
      this.telemetry.setNetworkEvent(val);
    }
  }

  set callErrors(val: CallError) {
    this.callErrorsSubject.next(val);
    this.telemetry.event('Join Call Failed', {
      error: val,
    });
  }

  set localParticipantId(val: string) {
    if (val !== this.localParticipantIdSubject.value) {
      this.localParticipantIdSubject.next(val);
    }
  }

  set callReconnecting(val: boolean) {
    if (this.callReconnectingSubject.value !== val) {
      this.callReconnectingSubject.next(val);
    }
  }

  set currentActiveSpeaker(val: ActiveSpeaker | undefined) {
    this.currentActiveSpeakerSubject.next(val);
  }

  set transcriptMessage(val: TranscriptResult) {
    this.transcriptMessageSubject.next(val);
  }

  set recordingEvent(val: RecordingEvent | undefined) {
    this.recordingEventSubject.next(val);
  }

  set participantMetadataChange(participant: CallParticipant) {
    this.participantMetadataChangeSubject.next(participant);
  }

  // #endregion Observable setter

  // ---- Public Methods ----

  public participants$: Observable<Record<string, CallParticipant>> = this.callConnected$.pipe(
    // Reset whenever the call connects
    switchMap(() => this._participants$),
  );

  public numberOfParticipants$ = this.participants$.pipe(
    map((participants) => Object.keys(participants).length),
    distinctUntilChanged(),
  );

  public localParticipantEvents$ = this.participantEvents$.pipe(
    filterNil(),
    filter((event) => Boolean(event.participant.local)),
    map((e) => e.participant),
  );

  // ---- Private Methods ----

  private _participants$ = this.participantEvents$.pipe(
    scan<ParticipantEvent | null, Record<string, CallParticipant>>((acc, event) => {
      if (!this.callConnectedSubject.value) {
        // reset the participants when the call disconnected
        // note: this works as a new subscription is happening whenever call connected/disconnected
        Object.keys(acc).forEach((key) => delete acc[key]);
        return acc;
      }
      if (!event) {
        return acc;
      }

      switch (event.action) {
        case ParticipantEventAction.JOINED:
        case ParticipantEventAction.UPDATED:
          acc[event.participant.participantId] = event.participant;
          break;
        case ParticipantEventAction.LEFT:
          delete acc[event.participant.participantId];
          break;
      }
      return acc;
    }, {}),
  );

  private resetBehaviourSubs() {
    this.callReady = false;
    this.callConnected = false;
    this.participantEvents = null;
    this.trackEvents = null;
    this.networkEvents = null;
    this.localParticipantId = '';
    this._localParticipant = undefined;
  }

  resetLocalParticipantIdBehaviorSub() {
    this.localParticipantId = '';
  }

  // Used to log Participant & Track events on prod
  private logMeetingEventsToTelemetry(meetingEvent: ParticipantEvent | TrackEvent) {
    this.telemetry.event(`[Meeting Event] ${meetingEvent.action}`, {
      participantName: meetingEvent.participant.name,
      data: JSON.stringify(meetingEvent),
    });
  }

  private logFirstJoinCallAttemptLocalParticipant() {
    const isFirstJoinCallAttempt = localStorage.getItem('firstJoinCallAttempt') ?? 'true';

    if (JSON.parse(isFirstJoinCallAttempt)) {
      this.telemetry.event('v1_first_successful_start_join_call_attempt', {});
      localStorage.setItem('firstJoinCallAttempt', JSON.stringify(false));
    }
  }

  private logPerfScenarioJoinCallProcess(userId: string) {
    this.telemetry.endPerfScenario(KeyScenariosOnSpaces.JOIN_CALL, {
      userId: userId,
    });
    this.telemetry.event('joined_or_started_call');
  }

  private isSameUserJoinedFromAnotherDevice(val: ParticipantEvent | null) {
    return (
      !val?.participant.local &&
      val?.action === ParticipantEventAction.JOINED &&
      val.participant.userId === this.userService.userId
    );
  }

  private handleMultiJoinCallFromTheSameUser(val: ParticipantEvent) {
    if (!val.participant.joinedAt || !this._localParticipant?.joinedAt) {
      Sentry.captureException('Participant Join at date should exist for join events');
    } else if (
      val.participant.joinedAt > this._localParticipant.joinedAt ||
      (val.participant.joinedAt == this._localParticipant.joinedAt &&
        val.participant.participantId < this.localParticipantId)
    ) {
      // in case two instances of the same user joined at the same time we will eject
      // the one with higher participant id to make it consistant into who will remain in the call
      this.sameUserJoinedFromDifferentDevice.next();
    }
  }
}
